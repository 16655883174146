<template>
  <div class="errorModal" v-show="errMsgShow">
    <div class="error_container">
      <p v-html="errMsg" class="error_title"></p>
      <div class="error_ok"><span @click="closeMsg" >閉じる</span></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errMsgShow: {
      type: Boolean,
      default: false,
    },
    errMsg: {
      default: "",
    },
  },
  methods: {
    closeMsg() {
      this.$emit("update:errMsgShow", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/lib.scss";
.errorModal {
  position: fixed;
  z-index: 999999;
  top: 0;
  width: 100%;
  height: 100%;
  .error_container {
    position: fixed;
    width: 300px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.5);
    .error_title {
      height: 60px;
      line-height: 60px;
      padding-left: 30px;
    }
    .error_ok {
      border-top:1px solid #bfb9b0;
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      text-align: right;
      color: #cc4466;
      line-height: 50px;
      padding-right: 30px;
      span{
        cursor: pointer;
      }
    }
  }
}
@media screen and (min-width: 416px) {
  .errorModal {
    .error_container {
      width: 400px;
      font-size: 12px;
      border-radius: 5px;
      .error_title {
        padding-left: 30px;
        height: 60px;
        line-height: 60px;
      }
      .error_ok {
        width: 100%;
        height: 50px;
        line-height: 50px;
      }
    }
  }
}
</style>
